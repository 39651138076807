








import { Component, Vue } from 'vue-property-decorator';
import { LanguageModule, language } from '@/store/modules/language';
import { BattleModule } from '@/store/modules/battle';
import Fountain from '@/utils/particleClick';
import eventBus from '@/utils/eventBus';
import { UserModule } from '@/store/modules/user';
import Loader from '@/components/Loader.vue';
import { LANGUAGE,  } from '@/consts/localStorage';

@Component({
    components: { Loader }
})
export default class App extends Vue {
    private isModalExist: boolean = false;

    private async created(): Promise<void> {
        // @ts-ignore
        const detectedLang = String(navigator.language || navigator.userLanguage).toLocaleLowerCase();
        let lang: language = 'en-US';

        if (detectedLang === 'ru-ru' || detectedLang === 'ru') {
            lang = 'ru-RU';
        }

        if (detectedLang === 'zh-cn') {
            lang = 'zh-CN';
        }

        if (localStorage.getItem(LANGUAGE)) {
            lang = localStorage.getItem(LANGUAGE) as language;
        }

        LanguageModule.changeAppLanguage(lang);
        UserModule.deleteOldTemplates();
        await UserModule.getTimeOffset();
        setInterval(() => BattleModule.setCurrentTime(), 1000);
    }

    private mounted(): void {
        const f = new Fountain();

        f.addHandlers();
        f.loop();

        eventBus.on('changeClickSrc', (src) => {
            f.src = src;
        });
    }

    private onModalPortalChange(isModalExist: boolean): void {
        this.isModalExist = isModalExist;
    }
}
