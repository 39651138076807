import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import i18n from './i18n';
import clickOutside from '@/directives/clickOutside';
import '@/filters';
import moment from 'moment';
import '@/utils/moment/ruLocale';
import '@/utils/moment/enLocale';
import PortalVue from 'portal-vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

Sentry.init({
    dsn: 'https://e32295a0a797480b81d3afeecdd93247@o395973.ingest.sentry.io/5403959',
    integrations: [new VueIntegration({ Vue, attachProps: true })],
});

Vue.config.productionTip = false;
Vue.directive('click-outside', clickOutside);

Vue.prototype.$mom = moment;
Vue.use(PortalVue);

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
