import Vue from 'vue';
import {
    VuexModule, Module, Mutation, Action, getModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { IHttpResponse } from '@/store/IHttpResponce';
import http from '@/http';
import { IBattleInfo, ILeader } from '@/store/types';
import { UserModule } from './user';

export const COUNT_LEADERS_PER_PAGE = 15;

@Module({ name: 'battle', store, dynamic: true, namespaced: true })
class Battle extends VuexModule {
    public currentTime: Date = new Date();

    public leaders: { [page: number]: ILeader[] } = {};

    public pageUpdates: { [page: number]: number } = {};

    public leadersCount: number = 0;

    public meInLeaderBoard: ILeader = {} as ILeader;

    public upcomingBattles: { [id: string]: IBattleInfo } = {};

    public liveBattles: { [id: string]: IBattleInfo } = {};

    public finishedBattles: { [id: string]: IBattleInfo } = {};

    public currentBattleId: number = -1;

    @Action
    public async getLeaders(page: number = 0): Promise<IHttpResponse> {
        const UPDATE_INTERVAL = 20 * 1000;
        const updatedTime = this.pageUpdates[page] ?? 0;

        if (updatedTime + UPDATE_INTERVAL > Date.now()) {
            return { status: 200 };
        }

        try {
            const res = await http.get(`battles/${BattleModule.currentBattleId}/leaders/`, {
                params: {
                    limit: COUNT_LEADERS_PER_PAGE,
                    offset: page * COUNT_LEADERS_PER_PAGE,
                },
            });

            this.setLeaders({ ...res.data, page });

            return { status: res.status };
        } catch (e) {
            return { status: e.response?.status };
        }
    }

    @Action
    public async getBattleById(id: string): Promise<IHttpResponse> {
        try {
            const res = await http.get(`battles/${id}/`);

            return { status: res.status, data: res.data };
        } catch (e) {
            return { status: e.response?.status };
        }
    }

    @Action
    public async getUpcoming(offset: number = 0): Promise<IHttpResponse> {
        try {
            const res = await http.get('battles/', {
                params: {
                    limit: null,
                    state: 'upcoming',
                    offset
                },
            });

            this.setUpcomingBattles({ battles: res.data.items, isUpdate: offset === 0 });

            return { status: res.status };
        } catch (e) {
            return { status: e.response?.status };
        }
    }

    @Action
    public async getLive(): Promise<IHttpResponse> {
        try {
            const res = await http.get('battles/', {
                params: {
                    limit: null,
                    state: 'started',
                },
            });

            this.setLiveBattles(res.data.items);

            return { status: res.status };
        } catch (e) {
            return { status: e.response?.status };
        }
    }

    @Action
    public async getFinished(): Promise<IHttpResponse> {
        try {
            const res = await http.get('battles/', {
                params: {
                    limit: null,
                    state: 'finished',
                },
            });

            this.setFinishedBattles(res.data.items);

            return { status: res.status };
        } catch (e) {
            return { status: e.response?.status };
        }
    }

    @Mutation
    public setCurrentTime(): void {
        this.currentTime = new Date(Date.now() - UserModule.timeOffset);
    }

    @Mutation
    public setBattleId(battleId: number): void {
        this.currentBattleId = battleId;
    }

    @Mutation
    private setUpcomingBattles(payload: { battles: IBattleInfo[], isUpdate: boolean }): void {
        const current = payload.isUpdate ? {} : { ...this.upcomingBattles };

        for (const battle of payload.battles) {
            const id = battle.id;

            current[id] = battle;
        }

        this.upcomingBattles = current;
    }

    @Mutation
    private setLiveBattles(battles: IBattleInfo[]): void {
        const current = { ...this.liveBattles };

        for (const battle of battles) {
            const id = battle.id;

            current[id] = battle;
        }

        this.liveBattles = current;
    }

    @Mutation
    private setFinishedBattles(battles: IBattleInfo[]): void {
        const current: { [id: string]: IBattleInfo } = {};

        for (const battle of battles) {
            const id = battle.id;

            current[id] = battle;
        }

        this.finishedBattles = current;
    }

    @Mutation
    private setLeaders({ page, items, total_count, me }: { page: number, items: ILeader[], total_count: number, me: ILeader }): void {
        const currentLeaders = { ...this.leaders };
        const pageUpdates = { ...this.pageUpdates };

        currentLeaders[page] = items;
        pageUpdates[page] = Date.now();

        this.pageUpdates = pageUpdates;
        this.leaders = currentLeaders;
        this.leadersCount = total_count;
        this.meInLeaderBoard = me;
    }

    @Mutation
    public clearLeaders(): void {
        this.leadersCount = 0;
        this.leaders = {};
        this.meInLeaderBoard = {} as ILeader;
        this.pageUpdates = {};
    }

    @Mutation
    public changeStateToLive(id: string | number): void {
        Vue.set(this.liveBattles, id, this.upcomingBattles[id]);
        Vue.delete(this.upcomingBattles, id);
    }

    @Mutation
    public changeStateToFinished(id: string | number): void {
        Vue.set(this.finishedBattles, id, this.liveBattles[id]);
        Vue.delete(this.liveBattles, id);
    }
}

export const BattleModule = getModule(Battle);
