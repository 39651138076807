interface IParticle {
    element: HTMLElement;
    size: number;
    speedHorz: number;
    speedUp: number;
    spinVal: number;
    spinSpeed: number;
    top: number;
    left: number;
    direction: -1 | 1;
}

class Fountain {
    public src: string = '/img/moni-eye.svg';

    private readonly app = document.getElementById('app')!;

    private readonly limit = 35;

    private readonly sizes = [15, 20, 25, 35, 45];

    private autoAddParticle: boolean = false;

    private particles: IParticle[] = [];

    private mouseY: number = 0;

    private mouseX: number = 0;

    public loop() {
        if (this.autoAddParticle && this.particles.length < this.limit) {
            this.createParticle();
        }

        this.updateParticles();

        requestAnimationFrame(this.loop.bind(this));
    }

    public addHandlers() {
        const tap = 'mousedown';
        const tapEnd = 'mouseup';
        const move = 'mousemove';

        document.addEventListener(move, (e: any) => {
            this.mouseX = e.pageX ?? (e.touches ? e.touches[0]?.pageX : 0);
            this.mouseY = e.pageY ?? (e.touches ? e.touches[0]?.pageY : 0);
        }, { passive: false });

        document.addEventListener(tap, (e: any) => {
            this.mouseX = e.pageX ?? (e.touches ? e.touches[0]?.pageX : 0);
            this.mouseY = e.pageY ?? (e.touches ? e.touches[0]?.pageY : 0);

            this.autoAddParticle = true;
        });

        document.addEventListener(tapEnd, () => {
            this.autoAddParticle = false;
        });

        document.addEventListener('mouseleave', () => {
            this.autoAddParticle = false;
        });
    }

    public createParticle() {
        const size = this.sizes[Math.floor(Math.random() * this.sizes.length)];
        const speedHorz = Math.random() * 10;
        const speedUp = Math.random() * 25;
        const spinVal = Math.random() * 360;
        const spinSpeed = ((Math.random() * 35)) * (Math.random() <= .5 ? -1 : 1);
        const top = (this.mouseY - size / 2);
        const left = (this.mouseX - size / 2);
        const direction = Math.random() <= .5 ? -1 : 1;

        const particle = document.createElement('img');
        particle.src = this.src;
        particle.style.width = '30px';
        particle.style.top = `${top}px`;
        particle.style.left = `${left}px`;
        particle.style.transform = `rotate(${spinVal}deg)`;
        particle.classList.add('particle');

        this.app.appendChild(particle);

        this.particles.push({
            element: particle,
            size,
            speedHorz,
            speedUp,
            spinVal,
            spinSpeed,
            top,
            left,
            direction,
        });
    }

    public updateParticles() {
        const height = document.body.clientHeight;

        this.particles.forEach((p) => {
            p.left = p.left - (p.speedHorz * p.direction);
            p.top = p.top - p.speedUp;
            p.speedUp = Math.min(p.size, p.speedUp - 1);
            p.spinVal = p.spinVal + p.spinSpeed;

            if (p.top >= height + p.size) {
                this.particles = this.particles.filter((o) => o !== p);
                p.element.remove();
            }

            p.element.style.top = `${p.top}px`;
            p.element.style.left = `${p.left}px`;
            p.element.style.transform = `rotate(${p.spinVal}deg)`;
        });
    }
}

export default Fountain;
