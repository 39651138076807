import axios from 'axios';
import { TOKEN } from '@/consts/localStorage';
import { LanguageModule } from '@/store/modules/language';

const http = axios.create({
    baseURL: process.env.VUE_APP_SERVER_API || 'https://api-battles.getmoni.io/',
});

http.interceptors.request.use(async (config) => {
    if (config.headers) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(TOKEN)}`;
        config.headers['Accept-Language'] = LanguageModule.currentLanguage;
    }

    return config;
});

export default http;
