type Event = 'pleaseAuth' | 'changeClickSrc' | 'followRules';
type Events = { [key in Event]: Callbacks };
type Callback = (...args: any[]) => void;
type Callbacks = Callback[];

class EventBus {
    public readonly events: Events = {} as Events;

    public emit(event: Event, ...payload: any[]): void {
        if (!this.events[event]) {
            return;
        }

        const callbacks: Callbacks = this.events[event];

        callbacks.forEach(cb => cb(...payload));
    }

    public on(event: Event, callback: Callback): void {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(callback);
    }
}

export default new EventBus();
