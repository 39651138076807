import {
    VuexModule, Module, Mutation, getModule, Action,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { IAddedCoin, IAsset, ICoin, IPortfolio } from '../types';
import { IHttpResponse } from '../IHttpResponce';
import http from '@/http';
import Vue from 'vue';
import { UserModule } from '@/store/modules/user';
import { ADDED_COINS } from '@/consts/localStorage';
import { BattleModule } from '@/store/modules/battle';

@Module({ name: 'portfolio', store, dynamic: true, namespaced: true })
class Portfolio extends VuexModule {
    public readonly initialBalance: number = 1000;

    public addedCoins: IAddedCoin[] = JSON.parse(localStorage.getItem(ADDED_COINS) as string) || [];

    public publishedPortfolio: IPortfolio = {} as IPortfolio;

    public selectedCoin: ICoin = {} as ICoin;

    public coins: ICoin[] = [];

    public get balance(): number {
        return this.initialBalance - this.addedCoins.reduce((acc: number, val: IAddedCoin) => {
            return acc + Number(val.amount_usd);
        }, 0);
    }

    public get isEditable(): boolean {
        if (!this.publishedPortfolio.items || this.publishedPortfolio.items.length === 0) {
            return false;
        } else {
            const { items } = this.publishedPortfolio;

            // @ts-ignore
            return !items.every((c: IAsset) => Boolean(this.addedCoins.find(addedCoin => addedCoin.coin_id === c.coin_id && +addedCoin.amount_usd === +c.amount_usd)));
        }
    }

    public get isPublished(): boolean {
        return Boolean(this.publishedPortfolio.name);
    }

    @Mutation
    public selectCoin(coin: ICoin): void {
        this.selectedCoin = coin;
    }

    @Mutation
    public addCoin(coin: IAddedCoin): void {
        this.addedCoins.push(coin);
        localStorage.setItem(ADDED_COINS, JSON.stringify(this.addedCoins));
    }

    @Mutation
    public deleteAddedCoin(index: number): void {
        Vue.delete(this.addedCoins, index);
        localStorage.setItem(ADDED_COINS, JSON.stringify(this.addedCoins));
    }

    @Mutation
    public setAddedCoins(coins: IAddedCoin[]): void {
        this.addedCoins = coins;
    }

    @Mutation
    public removeAddedCoins(): void {
        this.addedCoins = [];
        localStorage.removeItem(ADDED_COINS);
    }

    @Action
    public async getCoins(): Promise<IHttpResponse> {
        try {
            const res = await http.get(`battles/${BattleModule.currentBattleId}/coins/`);

            // @ts-ignore
            this.setCoins(res.data.items.map(item => ({ ...item, price: Number(item.price) })) as ICoin[]);

            return { status: 200 };
        } catch (e) {
            return { status: e?.response?.status };
        }
    }

    @Action
    public async checkNameFree(name: string): Promise<IHttpResponse> {
        try {
            const res = await http.get(`battles/${BattleModule.currentBattleId}/portfolio/_/check_if_name_is_free/?name=${name}`);

            return { status: res.status };
        } catch (e) {
            return { status: e.response?.status };
        }
    }

    @Action
    public async getPortfolio(): Promise<IHttpResponse> {
        try {
            const res = await http.get(`battles/${BattleModule.currentBattleId}/portfolio/`);

            PortfolioModule.setPublishedPortfolio(res.data);
            UserModule.setPortfolioName(this.publishedPortfolio.name);

            return { status: 200, data: res.data };
        } catch (e) {
            return { status: e.response?.status };
        }
    }

    @Action
    public async createPortfolio(): Promise<IHttpResponse> {
        try {
            const res = await http.put(`battles/${BattleModule.currentBattleId}/portfolio/`, {
                name: UserModule.currentPortfolioName,
                items: this.addedCoins.map(c => ({ coin_id: c.coin_id, amount_usd: c.amount_usd })),
            });

            this.setPublishedPortfolio(res.data);
            UserModule.setPortfolioName(this.publishedPortfolio.name);

            return { status: 200, data: res.data };
        } catch (e) {
            return { status: e.response?.status };
        }
    }

    @Action
    public async updatePortfolioName(): Promise<IHttpResponse> {
        try {
            const res = await http.put(`battles/${BattleModule.currentBattleId}/portfolio/`, {
                name: UserModule.currentPortfolioName,
                items: this.publishedPortfolio.items,
            });

            this.setPublishedPortfolio(res.data);
            UserModule.setPortfolioName(this.publishedPortfolio.name);

            return { status: 200, data: res.data };
        } catch (e) {
            return { status: e.response?.status };
        }
    }

    @Mutation
    private setCoins(coins: ICoin[]): void {
        this.coins = coins;
    }

    @Mutation
    public setPublishedPortfolio(portfolio: IPortfolio) {
        this.publishedPortfolio = portfolio;
    }
}

export const PortfolioModule = getModule(Portfolio);
