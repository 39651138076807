import Vue from 'vue';
import {
    VuexModule, Module, Mutation, getModule, Action,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { EMAIL, SAVED_TEMPLATES, TOKEN } from '@/consts/localStorage';
import { IAddedCoin, IPortfolio } from '@/store/types';
import { IHttpResponse } from '@/store/IHttpResponce';
import http from '@/http';
import { PortfolioModule } from '@/store/modules/portfolio';

interface ISavedTemplate extends IPortfolio {
    updatedDate: number;
}

@Module({ name: 'user', store, dynamic: true, namespaced: true })
class User extends VuexModule {
    public readonly maxPortfolioNameLength = 20;

    public email: string = localStorage.getItem(EMAIL) || '';

    public token: string = localStorage.getItem(TOKEN) || '';

    public savedTemplates: { [battleId: number]: ISavedTemplate } = JSON.parse(localStorage.getItem(SAVED_TEMPLATES) as string) || {};

    public currentPortfolioName: string = '';

    public timeOffset: number = 0;

    public get isAuthed(): boolean {
        return Boolean(this.token);
    }

    @Action
    public async getEmail(): Promise<IHttpResponse> {
        try {
            const res = await http.get('me/');

            this.setEmail(res.data.email);

            return { status: res.status };
        } catch (e) {
            return { status: e.response?.status };
        }
    }

    @Action
    public async login(email: string): Promise<IHttpResponse> {
        try {
            const res = await http.post('login/', { email });

            return { status: res.status };
        } catch (e) {
            return { status: e.response?.status, data: { retryAfter: e.response?.headers['retry-after'] } };
        }
    }

    @Action
    public async getTimeOffset(): Promise<IHttpResponse> {
        try {
            const res = await http.get('server_time/');

            this.setServerTime(res.data);

            return { status: 200 };
        } catch (e) {
            return { status: e.response?.status };
        }
    }

    @Mutation
    private setServerTime(serverTime: number): void {
        this.timeOffset = Date.now() - serverTime;
    }

    @Mutation
    public signout(): void {
        this.token = '';
        localStorage.removeItem(TOKEN);
    }

    @Mutation
    public setToken(token: string): void {
        this.token = token;
        localStorage.setItem(TOKEN, token);
    }

    @Mutation
    public setEmail(email: string): void {
        this.email = email;
        localStorage.setItem(EMAIL, email);
    }

    @Mutation
    public setSavedTemplate(payload: { battleId: number, template: ISavedTemplate }): void {
        const { battleId, template } = payload;

        template.updatedDate = Date.now();

        Vue.set(this.savedTemplates, battleId, template);

        localStorage.setItem(SAVED_TEMPLATES, JSON.stringify(this.savedTemplates));
    }

    @Mutation
    public setPortfolioName(name: string): void {
        this.currentPortfolioName = name;
    }

    @Mutation
    public setCurrentInfoFromTemplate(battleId: number): void {
        const savedTemplate = this.savedTemplates[battleId];

        if (savedTemplate) {
            this.currentPortfolioName = savedTemplate.name;
            PortfolioModule.setAddedCoins(savedTemplate.items as IAddedCoin[]);
        }
    }

    @Mutation
    public replaceTemplates(templates: { [battleId: number]: ISavedTemplate }): void {
        this.savedTemplates = templates;
        localStorage.setItem(SAVED_TEMPLATES, JSON.stringify(templates));
    }

    @Action
    public deleteOldTemplates(): void {
        const week = 604800000;
        const templates = this.savedTemplates;
        const newTemplates: { [battleId: number]: ISavedTemplate } = {};

        for (const key in templates) {
            if (templates[key].updatedDate > Date.now() - week) {
                newTemplates[key] = templates[key];
            }
        }
        this.replaceTemplates(newTemplates);
    }
}

export const UserModule = getModule(User);
