import debounce from 'lodash.debounce';

export const round = (num: number, decimals: number = 2, addDecimalsForLow: boolean = false): number => {
    if (Math.abs(num) < 1 && addDecimalsForLow) {
        decimals += 3;
    }
    if (!String(num).includes('e')) {
        return Number(`${Math.round(+`${num}e+${decimals}`)}e-${decimals}`);
    }
    const numberAndFraction = String(num).split('e');
    let sign = '';
    if (+numberAndFraction[1] + decimals > 0) {
        sign = '+';
    }

    const resultNum = Math.round(Number(`${numberAndFraction[0]}e${sign}${+numberAndFraction[1] + decimals}`));

    return Number(`${resultNum}e-${decimals}`);
};

export const splitDigits = (num: number | string): string => {
    const splitted = String(num).split('.');

    if (splitted.length === 1) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    splitted[0] = splitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return splitted.join('.');
};


export function Debounce(delay: number = 500, immediate: boolean = false) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const original = descriptor.value;

        descriptor.value = debounce(original, delay, { leading: immediate });

        return descriptor;
    };
}


