import {
    VuexModule, Module, Mutation, Action, getModule,
} from 'vuex-module-decorators';
import moment from 'moment';
import store from '@/store/store';
import i18n from '@/i18n';
import { LANGUAGE } from '@/consts/localStorage';
import { LANGUAGE_NAMES } from '@/consts/common';

export type language = 'ru-RU' | 'en-US' | 'zh-CN';

@Module({ name: 'language', store, dynamic: true, namespaced: true })
class Language extends VuexModule {
    // TODO: remove 'zh-CN' when needed
    public readonly locales: language[] = ['en-US', 'ru-RU'];

    public currentLanguage: language = 'en-US';

    @Action
    public async changeAppLanguage(lang: language) {
        this.changeLanguage(lang);
        await import(`@/langs/${lang}`).then((res) => {
            i18n.locale = lang;
            i18n.setLocaleMessage(lang, res.default);
        });
    }

    @Mutation
    private changeLanguage(lang: language): void {
        this.currentLanguage = lang;
        localStorage.setItem(LANGUAGE, lang);
        moment.locale(lang);

        document.documentElement.lang = LANGUAGE_NAMES[lang];
    }
}

export const LanguageModule = getModule(Language);
