import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

const BattleViewHandler = () => import('@/views/BattleViewHandler.vue');
const BattlesListPage = () => import('@/views/BattlesListPage.vue');
const MagicLink = () => import('@/views/MagicLink.vue');
const BecomePartner = () => import('@/views/BecomePartner.vue');
const NotFound = () => import('@/views/NotFound.vue');

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'BattlesListPage',
        component: BattlesListPage,
    },
    {
        path: '/magic',
        name: 'MagicLink',
        component: MagicLink,
    },
    {
        path: '/becomepartner',
        name: 'BecomePartner',
        component: BecomePartner,
    },
    {
        path: '/battle/:id',
        name: 'BattleViewHandler',
        component: BattleViewHandler,
        props: true,
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFound,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

export default router;
