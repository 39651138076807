import { DirectiveBinding } from 'vue/types/options';

const CACHE_CLICK_OUTSIDE = '__vueClickOutside__';

export default {
    bind(el: HTMLElement, binding: DirectiveBinding): void {
        // Validate binding
        if (typeof binding.value !== 'function') {
            const warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;

            console.warn(warn);
        }

        if (!binding.arg) {
            setTimeout(() => addClickEvent(el, binding.value));
        }
    },
    update(el: HTMLElement, binding: DirectiveBinding): void {
        if (binding.arg) {
            removeClickEvent(el);
        } else {
            setTimeout(() => addClickEvent(el, binding.value));
        }
    },
    unbind(el: HTMLElement): void {
        removeClickEvent(el);
    },
};

function addClickEvent(el: HTMLElement, callbackOnClickOutside: (event: MouseEvent) => void) {
    const handler = (event: MouseEvent) => {
        if (!el.contains(event.target as Node) && el !== event.target) {
            callbackOnClickOutside(event);
        }
    };

    document.addEventListener('click', handler);
    // @ts-ignore
    el[CACHE_CLICK_OUTSIDE] = handler;
}

function removeClickEvent(el: HTMLElement): void {
    // @ts-ignore
    document.removeEventListener('click', el[CACHE_CLICK_OUTSIDE]);
    // @ts-ignore
    delete el[CACHE_CLICK_OUTSIDE];
}
